<template>
  <div>
    <h2>Équipement</h2>
    <div id="tools-container">
      <div class="tools-container_part">
        <h3>Numérique:</h3>
        <ul>
          <li v-for="(article, index) in contentArray.logiciels" v-bind:key="index">{{article}}</li>
        </ul>
      </div>
      <div class="tools-container_part">
        <h3>Écoutes:</h3>
        <ul>
          <li v-for="(article, index) in contentArray.ecoutes" v-bind:key="index">{{article}}</li>
        </ul>
      </div>
      <div class="tools-container_part">
        <h3>Périphériques:</h3>
        <ul>
          <li v-for="(article, index) in contentArray.peripheries" v-bind:key="index">{{article}}</li>
        </ul>
      </div>
      <div class="tools-container_part">
        <h3>Compresseurs:</h3>
        <ul>
          <li v-for="(article, index) in contentArray.compresseurs" v-bind:key="index">{{article}}</li>
        </ul>
      </div>
      <div class="tools-container_part">
        <h3>Égalisateurs:</h3>
        <ul>
          <li v-for="(article, index) in contentArray.egalisateurs" v-bind:key="index">{{article}}</li>
        </ul>
      </div>
      <div class="tools-container_part">
        <h3>Plugins:</h3>
        <ul>
          <li v-for="(article, index) in contentArray.plugins" v-bind:key="index">{{article}}</li>
        </ul>
      </div>
    </div>
    <div id="logo-container">
      <svg role="img" aria-label="Logo de Protools" class="logo" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Protools</title><path d="M.505 15.452Q.27 14.63.14 13.762.01 12.891.01 12q0-2.49.94-4.673.94-2.184 2.572-3.804Q5.155 1.902 7.339.938 9.523 0 12.012 0q2.465 0 4.65.94 2.183.962 3.815 2.583 1.633 1.62 2.572 3.804.94 2.184.94 4.673 0 .869-.118 1.714-.118.846-.352 1.644.21.047.34.07l.13.024-1.363 3.429-.188-.012-.54-.106Q21.1 19.937 20.02 20.9q-1.057.987-2.325 1.668-1.268.68-2.724 1.056-1.433.376-2.96.376-1.55 0-3.005-.376-1.433-.375-2.7-1.068-1.269-.693-2.35-1.656-1.08-.963-1.878-2.16-.282.094-.434.117l-.153.024-1.48-3.382.118.012.376-.059zM22.744 12q0-2.23-.846-4.18-.845-1.95-2.301-3.405-1.456-1.456-3.429-2.301-1.949-.846-4.156-.846-2.231 0-4.18.846-1.973.845-3.429 2.3Q2.947 5.872 2.102 7.82 1.256 9.77 1.256 12q0 .751.106 1.491.106.74.317 1.444.892-.516 2.02-1.972 1.127-1.456 1.808-2.912.352-.728.916-1.597.54-.869 1.338-1.632.799-.763 1.855-1.256 1.057-.517 2.396-.517 1.315 0 2.419.587 1.103.587 1.913 1.35.81.764 1.304 1.492.516.727.657.986.165.282.47.94.329.633.728 1.361.4.728.822 1.433.423.68.798 1.033.259.258.564.446.305.188.61.329.212-.728.33-1.48.117-.751.117-1.526zM12.012 22.732q1.338 0 2.583-.305 1.268-.33 2.383-.916 1.116-.587 2.055-1.41.94-.821 1.668-1.83-.94-.494-2.173-1.645-1.233-1.15-2.5-3.358-.142-.235-.494-.94-.352-.704-.857-1.455-.505-.752-1.115-1.339t-1.268-.587q-.681 0-1.386.634-.704.61-1.303 1.386-.6.775-1.022 1.503-.423.704-.54.916-1.174 2.066-2.477 3.205-1.304 1.139-2.29 1.656.728 1.01 1.667 1.831.963.846 2.079 1.433 1.115.587 2.36.892 1.268.329 2.63.329z"/></svg>
      <svg role="img" aria-label="Logo de Antelope Audio" class="logo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 652 652" >
        <title>Antelope Audio</title>
        <g>
          <path d="M422.4,407.6c-28,0-36.1,15-36.1,26.8s8,26.8,36.1,26.8c28,0,36.1-15,36.1-26.8S450.5,407.6,422.4,407.6 M422.4,450.3
            c-8.9,0-15.9-5.6-15.9-15.9c0-10.2,6.9-15.9,15.9-15.9c8.9,0,15.9,5.6,15.9,15.9C438.3,444.6,431.4,450.3,422.4,450.3"/>
                  <rect x="350.5" y="387.7" width="19" height="71.5"/>
                  <path d="M587.9,450.8c-8,0-12.6-4-13-11.8H620c-0.1-19.4-8.4-31.4-32.7-31.4c-17.1,0-32,8.3-32,27.6c0,19.6,15.1,26,32.3,26
            c13.8,0,27.3-1.3,31.3-17.1h-20.2C597.7,447.5,596.2,450.8,587.9,450.8 M587.5,418c6.8,0,12.1,2.7,12.3,10.8h-24.6
            C576.1,423.6,578.7,418,587.5,418"/>
                  <path d="M515.3,407.6c-12.4,0-17.9,4.9-22.3,10.1c0-2.8-0.1-5.4-0.4-8.2h-18.4c0.3,2.7,0.4,5.1,0.4,8.2v60.6h19v-25.6
            c3.9,4,10.2,8.4,21.2,8.4c16.7,0,28.1-10,28.1-26.5C542.9,417.7,531.1,407.6,515.3,407.6 M508.4,449.7c-8.6,0-14.8-6-14.8-15.7
            c0-8.8,6.2-14.9,14.8-14.9c9.8,0,14.4,7.5,14.4,15.6C522.7,443.4,516.8,449.7,508.4,449.7"/>
                  <path d="M172.8,407.6c-8.7,0-16.2,4.2-21.1,10.8h-0.2c0-3-0.2-5.9-0.4-8.9h-18.4c0.3,3.4,0.4,7,0.4,10.2v39.5h19v-21.9
            c0-11,6.8-17,14.6-17c8.6,0,12.1,5.6,12.1,13.5v25.5h19v-28.3C197.8,418,191,407.6,172.8,407.6"/>
                  <path d="M301,407.6c-17,0-32,8.3-32,27.6c0,19.6,15.1,26,32.3,26c13.8,0,27.4-1.3,31.3-17.1h-20.2c-1,3.4-2.5,6.7-10.8,6.7
            c-8,0-12.6-4-13-11.8h45.1C333.6,419.6,325.2,407.6,301,407.6 M288.9,428.8c0.9-5.1,3.5-10.8,12.3-10.8c6.7,0,12.1,2.7,12.3,10.8
            H288.9z"/>
                  <path d="M242.3,392.5l-19,6.9v10.1h-12.2v12.1h12.2v21.1c0,12.1,7.7,17.8,22.3,17.8c6,0,11-0.9,12.8-1.2v-12.1
            c-3,0.4-5.7,0.6-7.5,0.6c-7.6,0-8.5-3.7-8.5-10.6v-15.6h16v-12.1h-16V392.5z"/>
                  <path class="st0" d="M96.4,361c6.6-10.2,12.7-19,17.6-20.6c14.3-2.1,21.8,2.7,34.3-0.2c18-4.1,21.2-15.3,28.4-28.2
            c1.4-2.4,4.7-3.8,6.2-6.2c23.1-1.5,34,7.4,40.6,7c9.6-0.5,29.5-4.6,47.8-20.9c5.7-4.6,35.5-28.3,35.2-28c-2.3,2-4.7,9.7-8.4,16
            c-1.3,2.2-3.4,2.2-4.4,3.3c-1.7,1.8-2.2,3.8-5.1,3.7l-4.6,5.2l-1.2,4c7.6,0.2,14.3-1.9,19-7.5c3.2-3.8,4.7-10.3,7.9-14.9
            c9.2-13.1,22.2-18.2,23.6-26.6c0.4-2.7-5.2-11.4-7.6-10.5c-8.2,3.3-26.7,14-36.4,18.5l-3.1,0.6c5.2-10,1.8-38.9-17.9-50.5l-3.7-1.8
            l-4.5-0.9c-4.2-4.2-6.1-4.7-7.9-10.1c7.3,1.8,12.6-3.3,19.6-6c6.5-2.5,7.6,3.7,11,0.5c1.1-1-0.7-3-1.1-4.5l0.8-2.1l-3.7-4.3
            c-1.2-1.4-26.9-2.5-41-5.4l-11.4-3c-21.4-5.6-48.9,11.3-70.9,7.6c-10.8-1.8-16.2-8.4-16.2-8.3c2.1,4.6,7.9,10.9,19.3,12.6
            c14.1,2,33.6-3.4,48.5-3.7c6.8-0.1,16.5,4.9,20.4,5.6c-3.2,16.1-2.9,26.5,20.1,51.4c-17.8,7.4-31.5,32.5-44.9,35.6
            c-8.5,1.9-30.9-13.8-67.8,10.1c-12.2-5.9-5.2-18.4-6.1-19.1c-1.8-1.2-10.6,10.2-3,21.6c2.9,4.4,0.2,13.4-0.1,21.7
            c-0.4,11.3,6.9,16.7-4.2,21.5c-2.5,1.1-5.7,5-9,6.2c-3.3,1.2-6.8-0.4-11.8-0.5c-1.6,2.4-3.3,8-4.1,12.3c-0.6,3.8-3.5,6.8-5.2,10.4
            c-1.5,3.2-1.7,7.2-3.3,10.3c-4,7.6-9,10.5-14.4,11.7c-0.9,2.6-1.3,6.3-4.3,7c-4.1,0.9-8.4,3.8-11.5,6.1H58l0,0.1c0,0,0,0,0,0h0
            L20,459.3h21.4l6.9-14.2H90l6.7,14.2H120l-37.2-71.5H66.7c1.6-0.6,3.4-1.4,5.2-2.5C80.1,380.4,90,371.1,96.4,361 M83.7,431.8H54.8
            l14.7-30.2L83.7,431.8z"/>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolsSponso',
  data(){
    return {
      electricBlue: "#4EBBC2",
      darkElectricBlue: "#557F82",
      smoothBlack: "#1F2222",
      smoothWhite: "#FFF9F3",
      contentArray: []
    }
  },
  methods:{
    async loadArticlesJSON(){
      try {
        const response = await fetch('/data/content.json');
        if (response.ok) {
          const data = await response.json();
          this.contentArray = data.equipement;
        } else {
          console.error('Erreur de chargement des données JSON');
        }
      } catch (error) {
        console.error('Erreur:', error);
      }
    }
  },
  beforeMount() {
    this.loadArticlesJSON()
  }
}
</script>

<style>
#logo-container{
  margin-top: 4rem;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 4rem;
}
#logo-container .logo{
  height: 5rem;
}
#tools-container{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 30px;
}
.tools-container_part {
  min-width: 25vw;
  max-width: 40vw;
}

#logo-container .logo *{
  fill: v-bind(smoothWhite);
}
@media screen and (max-width: 800px) {
  #tools-container{
    flex-flow: column wrap;
    gap: 0;
  }
  .tools-container_part {
    max-width: 80vw;
  }
}
</style>