<template>
  <div id="page-container">
    <nav :class="NavIsToggled&&windowWidth<800? 'toggleNavBar': 'navbar'">
      <div class="logo" id="left-side-navbar">
        <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.93 65.76">
          <g id="Piste">
            <g>
              <path d="M84.18,14.24V51.52c0,1,.81,1.81,1.81,1.81s1.81-.81,1.81-1.81V14.24c0-1-.81-1.81-1.81-1.81s-1.81,.81-1.81,1.81"/>
              <path d="M11.95,14.24V51.52c0,1,.81,1.81,1.81,1.81s1.81-.81,1.81-1.81V14.24c0-1-.81-1.81-1.81-1.81s-1.81,.81-1.81,1.81"/>
              <path d="M72.15,26.45v12.86c0,1,.81,1.81,1.81,1.81s1.81-.81,1.81-1.81v-12.86c0-1-.81-1.81-1.81-1.81s-1.81,.81-1.81,1.81"/>
              <path d="M24.02,26.45v12.86c0,1,.81,1.81,1.81,1.81s1.81-.81,1.81-1.81v-12.86c0-1-.81-1.81-1.81-1.81s-1.81,.81-1.81,1.81"/>
              <path d="M96.3,30.23v5.3c0,1,.81,1.81,1.81,1.81s1.81-.81,1.81-1.81v-5.3c0-1-.81-1.81-1.81-1.81s-1.81,.81-1.81,1.81"/>
              <path d="M0,30.23v5.3c0,1,.81,1.81,1.81,1.81s1.81-.81,1.81-1.81v-5.3c0-1-.81-1.81-1.81-1.81s-1.81,.81-1.81,1.81"/>
              <path d="M36.08,18.11v29.54c0,1,.81,1.81,1.81,1.81s1.81-.81,1.81-1.81V18.11c0-1-.81-1.81-1.81-1.81s-1.81,.81-1.81,1.81"/>
              <path d="M48.1,1.81V63.95c0,1,.81,1.81,1.81,1.81s1.81-.81,1.81-1.81V1.81c0-1-.81-1.81-1.81-1.81s-1.81,.81-1.81,1.81"/>
              <path d="M60.13,18.11v29.54c0,1,.81,1.81,1.81,1.81s1.81-.81,1.81-1.81V18.11c0-1-.81-1.81-1.81-1.81s-1.81,.81-1.81,1.81"/>
            </g>
          </g>
          <g id="Droite">
            <path d="M66.41,26.19c-.22-.22-.53-.35-.84-.35h-7.05c-.31,0-.62,.13-.84,.35-.22,.22-.35,.53-.35,.84v5.5c0,.31,.13,.62,.35,.84s.53,.35,.84,.35h7.05c.31,0,.62-.13,.84-.35,.22-.22,.35-.53,.35-.84v-5.5c0-.31-.13-.62-.35-.84Z"/>
          </g>
          <g id="Milieu">
            <path d="M54.56,42.78c-.22-.22-.53-.35-.84-.35h-7.05c-.31,0-.62,.13-.84,.35-.22,.22-.35,.53-.35,.84v5.5c0,.31,.13,.62,.35,.84s.53,.35,.84,.35h7.05c.31,0,.62-.13,.84-.35s.35-.53,.35-.84v-5.5c0-.31-.13-.62-.35-.84Z"/>
          </g>
          <g id="Gauche">
            <path d="M42.71,31.21c-.22-.22-.53-.35-.84-.35h-7.05c-.31,0-.62,.13-.84,.35-.22,.22-.35,.53-.35,.84v5.5c0,.31,.13,.62,.35,.84,.22,.22,.53,.35,.84,.35h7.05c.31,0,.62-.13,.84-.35,.22-.22,.35-.53,.35-.84v-5.5c0-.31-.13-.62-.35-.84Z"/>
          </g>
        </svg>
        <h1>David <br v-if="windowWidth<500">Jakoubovitch</h1>
      </div>
      <ol id="right-side-navbar" v-show="windowWidth<800 ? NavIsToggled: true">
        <li><a class="nav-link" aria-label="Aller sur la page Facebook" href="https://www.facebook.com/people/David-Jakoubovitch-Mixing/100019074090325/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg></a></li>
        <li><a class="nav-link" aria-label="Aller sur la page Instagram" href="https://www.instagram.com/davidj_mixing/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a></li>
        <li><a class="nav-link" aria-label="Aller sur la chaîne Youtube" href="https://youtube.com/@lessecretsduson?si=U4rmnjxt7XHmSuya" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg></a></li>
        <li><a class="nav-link" aria-label="Prendre contact via WhatsApp" href="https://wa.me/972542201984"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg></a></li>
      </ol>
      <button id="toggleButton" v-if="windowWidth<800" @click="toggle()" :aria-expanded="NavIsToggled" :aria-label="NavIsToggled? 'Fermer la barre de navigation': 'Ouvrir la barre de navigation'">
        <svg v-if="!NavIsToggled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          !--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
        </svg>
      </button>
    </nav>
    <div id="slideShow-container">
      <SlideshowPresentation />
      <p class="catch_phrase"><i>l'expérience</i> <br><span>au service de </span><br><i>l'excellence</i></p>
    </div>
    <main>
      <BiographyPresentation id="présentation" />
      <ToolsSponso />
    </main>
    <footer>
      <ContactForm id="contact"/>
      <p><small>&copy; Copyright David Jakoubovitch - 2024. Tous droits réservés. <br>
        <button id="legalnotices" type="button" @click="toggleLN" :aria-expanded="LNIsToggled" :aria-label="LNIsToggled? 'Fermer le contenu des mentions légales': 'Ouvrir le contenu des mentions légales'">{{LNIsToggled? 'Fermer les mentions légales':'Mentions légales'}}</button>
      </small></p>
      <hr v-show="LNIsToggled">
      <LegaleNotice v-show="LNIsToggled" :aria-disabled="!LNIsToggled" />
    </footer>
  </div>
</template>

<script>
import SlideshowPresentation from '@/components/1Slideshow/SlideshowPresentation.vue';
import BiographyPresentation from "@/components/2Bio/BiographyPresentation.vue";
import ToolsSponso from "@/components/3Tools/ToolsSponso.vue";
import ContactForm from '@/components/4ContactForm/ContactForm.vue';
import LegaleNotice from "@/components/5LegalNotice/LegaleNotice.vue";
import {useWindowSize} from "@vueuse/core";
export default {
  name: 'App',
  components: {
    SlideshowPresentation,
    BiographyPresentation,
    ToolsSponso,
    ContactForm,
    LegaleNotice
  },
  data(){
    return {
      electricBlue: "#4EBBC2",
      darkElectricBlue: "#557F82",
      smoothBlack: "#1F2222",
      smoothWhite: "#FFF9F3",
      NavIsToggled: false,
      LNIsToggled: false,
      windowWidth: this.width,
      windowHeight: this.height
    }
  },
  setup(){
    const { width, height } = useWindowSize()
    return { width, height }
  },
  methods: {
    blockRightClick(event) {
      event.preventDefault();
    },
    toggle(){
      this.NavIsToggled = !this.NavIsToggled;
    },
    toggleLN(){
      this.LNIsToggled = !this.LNIsToggled;
    },
    reSize(){
      const { width, height } = useWindowSize()
      this.windowWidth = width
      this.windowHeight = height
      if (width>800){
        this.NavIsToggled=false
      }
    }
  },
  mounted() {
    document.getElementById('app').addEventListener('contextmenu', this.blockRightClick);
    window.addEventListener('resize', this.reSize)
  },
  beforeUnmount() {
    document.getElementById('app').removeEventListener('contextmenu', this.blockRightClick);
    window.removeEventListener('resize', this.reSize)
  }
}
</script>

<style>
html {
  box-sizing: content-box;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0 0 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: 700;
}
code,
kbd,
samp {
  font-family: monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 7.5vw 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[hidden] {
  display: none;
}

/* Fin de normalisation de la page */

* {
  font-family: 'Raleway', sans-serif;
}

#page-container {
  background-color: v-bind(smoothBlack);
}

h1 {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: normal;
  margin-bottom: 0;
  color: v-bind(smoothWhite);
}

h2 {
  margin-top: 3.1415088rem;
  font-weight: 700;
  font-size: 3.1415088rem;
  color: v-bind(electricBlue);
}

h3 {
  margin-top: 1.9416rem;
  font-weight: 700;
  font-size: 1.9416rem;
  color: v-bind(smoothWhite);
}

p, span, label, figcaption, li {
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 2rem;
  color: v-bind(smoothWhite);
}

figcaption {
  font-weight: 500;
  font-size: 1.18rem;
  line-height: 2rem;
}

strong {
  font-weight: 700;
  font-style: normal;
}

em, i {
  font-style: italic;
}

main {
  padding: 5rem 7.5vw;
}

#slideShow-container {
  position: relative;
  max-width: 100vw;
  max-height: max(480px, calc(100svh - 8rem));
  overflow: hidden;
}

.catch_phrase {
  position: absolute;
  bottom: 2rem;
  left: 6rem;
}
.catch_phrase,
.catch_phrase span{
  font-weight: 900;
  font-size: 5.0829612384rem;
  line-height: 5rem;
  text-shadow: 0 0 20px v-bind(smoothBlack);
}
.catch_phrase span {
  color: v-bind(electricBlue);
}
@media screen and (max-width: 800px) {
  .catch_phrase {
    position: absolute;
    bottom: 3rem;
    left: 3rem;
  }
  .catch_phrase, .catch_phrase span{
    font-size: 1.9416rem;
    line-height: 1.8541rem;
 }
}
#slideShow-container:hover .catch_phrase {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

/* Pied de page */

footer {
  min-height: 100vh;
  padding: 5rem 7.5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

footer h2 {
  text-align: center;
}

footer p {
  line-height: normal;
  text-align: center;
  margin-top: 4rem;
}

footer small{
  color: v-bind(smoothWhite);
}
hr{
  width: 100%;
  color: v-bind(smoothWhite)
}
#legalnotices{
  background-color: transparent;
  color: v-bind(smoothWhite);
  border: none;
  outline: none;
  cursor: pointer;
}
#legalnotices:hover, #legalnotices:focus{
  color: v-bind(electricBlue);
  transition: color 0.5s ease-out;
}

/* Barre de navigation */
#logo {
  height: 5rem;
}

#logo #Piste path {
  fill: v-bind(smoothWhite);
}

#logo #Gauche path, #logo #Droite path, #logo #Milieu path {
  fill: v-bind(electricBlue);
  transition: 0.5s ease-in-out;
}

#logo:hover #Gauche path{
  transform: translateY(-0.5rem);
}
#logo:hover #Milieu path{
  transform: translateY(-2rem);
}
#logo:hover #Droite path{
  transform: translateY(-0.25rem);
}
@media screen and (max-width: 800px) {
  #logo {
    height: 4rem;
  }
  #logo:hover #Gauche path{
    transform: translateY(-0.4rem);
  }
  #logo:hover #Milieu path{
    transform: translateY(-1.6rem);
  }
  #logo:hover #Droite path {
    transform: translateY(-0.2rem);
  }
}
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 8rem;
  max-width: 100vw;
  color: v-bind(smoothWhite);
}

#left-side-navbar{
  display: flex;
  gap: 1.236rem;
  flex-direction: row;
  align-items: center;
  padding-right: 0;
}
#left-side-navbar>*{
  align-self: center;
}

#right-side-navbar {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
  gap: 1.236rem;
}

#right-side-navbar li {
  display: inline-block;
  list-style: none;
}

.nav-link {
  cursor: pointer;
}

.nav-link svg{
  height: 1.8rem;
}

.nav-link path{
  fill: v-bind(smoothWhite);
}

.nav-link:hover path{
  fill: v-bind(electricBlue);
}
@media screen and (max-width: 800px) {
  /* Toggle*/
  #toggleButton {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: flex;
    place-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }
  #toggleButton svg {
    height: 1.5rem;
  }
  #toggleButton path {
    fill: v-bind(smoothWhite);
  }
  #toggleButton:hover {
    background-color: v-bind(electricBlue);
    transition: background-color 0.5s ease-out;
  }
  .toggleNavBar {
    padding: 2rem;
    height: 16rem;
    max-width: 100vw;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
  }
  #right-side-navbar {
    gap: 2.7rem;
  }
}
</style>